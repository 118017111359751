import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  claimants: [],
  languageList: [],
  notificationcount: "",
  selectedTableRow: null,

  claimantFilters: {
    claimantID: 0,
    customerID: 0,
    customerName: "",
    firstName: "",
    lastName: "",
    email: "",
    languageCode: "",
    mobile: "", 
    inactive: -2,
    zipCode: 0,
    miles: 0,
  },
};

export const ClaimantsSlice = createSlice({
  name: "claimants",
  initialState,
  reducers: {
    loadUser: (state) => {
      if (!localStorage.getItem("userAuthApproved")) {
        state.userAuthApproved = false;
        return;
      }
      state.Token = localStorage.getItem("Admintoken");
      state.user = localStorage.getItem("Admin");
      state.userAuthApproved = true;
    },
    setCustomers: (state, action) => {
      state.customers = action.payload;
    },
    setClaimants: (state, action) => {
      state.claimants = action.payload;
    },
    setErrLoggingIn: (state, action) => {
      state.errLoggingIn = action.payload;
    },
    // Add other reducers here
    setLoginLoading: (state, action) => {
      state.loginLoading = action.payload;
    },
    setStatesList: (state, action) => {
      state.stateList = action.payload;
    },
    setCategoryList: (state, action) => {
      state.categoryList = action.payload;
    },
    setClaimantFilters: (state, action) => {
      state.claimantFilters = action.payload;
    },
    setLanguage: (state, action) => {
      state.languageList = action.payload;
    },
    updateClaimantFilters: (state, action) => {
      state.claimantFilters = { ...state.claimantFilters, ...action.payload };
    },
    resetClaimantFilters: (state) => {
      state.claimantFilters = { ...initialState.claimantFilters };
      state.selectedTableRow = null;
    },
    setSelectedRow: (state, action) => {
      state.selectedTableRow = action.payload;
    },
  },
});

export const {
  setErrLoggingIn,
  setLoginLoading,
  userAuthApproved,
  loadUser,
  setCustomers,
  setClaimants,
  setClaimantFilters,
  updateClaimantFilters,
  resetClaimantFilters,
  setSelectedRow,
  setCategoryList,
  setStatesList,
  setLanguage,
} = ClaimantsSlice.actions;

export default ClaimantsSlice.reducer;
