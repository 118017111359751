import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { clearNotificationCount, clearNotificationList } from "../../Redux-Toolkit/NotificationSlice";
import { useDispatch, useSelector } from "react-redux";
import { formatDateTimeFor_Today_Yesterdy_DaysAgo } from "../../utils/helpers";
import { getMethodData } from "../../ApiMethods/Api";
import { MdDelete } from "react-icons/md";
import { updateAssignmentFilters } from "../../Redux-Toolkit/AssignmentSlice";
import { toast } from "react-toastify";
import encryptParamID from "../../utils/encryptParamID";

const NotificationList = () => {

  const dispatch = useDispatch();

  const [notificationOpenModal, setNotificationOpenModal] = useState(false);
  const [notificationDataLoading, setNotificationDataLoading] = useState(false);

  const notificationSliceList = useSelector((state) => state?.notifications?.notificationList);
  const [notificationList, setNotificationList] = useState([]);
  const notificationCount = useSelector((state) => state?.notifications?.notificationCount);

  console.log("bjhbuyguyhbuygbj", notificationList);
  

  const setnotificationdeletepopup = () => {
    setNotificationOpenModal(!notificationOpenModal);
  };

  const goBack = () => {
    window.history.back();
  };

  const resetNotificationCount = () => {
    dispatch(clearNotificationCount());
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    resetNotificationCount();

    setNotificationDataLoading(true);

    getNotificationList("/NotificationsHistory", (response) => {
      if (response?.data?.data?.length === 0) {
        setNotificationList([]);
        setNotificationDataLoading(false);
      } else {
        setNotificationList(response?.data?.data);
        setNotificationDataLoading(false);
      }
    });
  }, [notificationSliceList, notificationCount]);

  const getNotificationList = async (url, callback) => {
    try {
      setNotificationDataLoading(true);
      const response = await getMethodData(url);
      setNotificationDataLoading(false);
      callback(response);
    } catch (error) {
      console.error(
        "An error occurred while trying to get the customer search list:",
        error
      );
      setNotificationDataLoading(false);
      dispatch(clearNotificationList());
    }
  };

  const getDeleteNotificationList = async () => {
    const url = "/DeleteNotificationsHistory";
    try {
      const response = await getMethodData(url);

      if (response?.data?.status === true) {
        dispatch(clearNotificationList());
        toast.success("Notifications are Deleted!!", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 2000,
        });
      }
    } catch (error) {
      console.error(
        "An error occurred while trying to get the customer category list:",
        error
      );
    }
  };

  /**
   * object to store notification types and their respective colors
   */
  const notificationTitle = {
    "Urgent attention required": "red",
  };

  const viewNotificationDetailsType = ["ASSIGNMENT_CANCELLED"];

  const deleteNotification = (() => {
    getDeleteNotificationList();
    setNotificationOpenModal(false);
  })

  return (
    <section className="h-full overflow-hidden w-full ">
      <div className="flex justify-between mb-5">
        <div className=" mb-4">
          <p className=" text-2xl font-semibold">Notifications</p>
        </div>

        <div className="flex justify-between  items-center">
          <Link
            onClick={goBack}
            className="btn btn-sm bg-[#000] text-green text-sm hover:bg-[#000] mb-[1%]"
          >
            Back
          </Link>

          {(notificationList >= [0]) ? (

            <button
              onClick={() => setnotificationdeletepopup()} // Open the popup
              className="hover:bg-red-700 text-[red] text-[2rem] mb-[1%] ml-5 flex items-center justify-center"
              title="Clear all notifications" // Tooltip text
            >
              <MdDelete />
            </button>
          )
          : ""
        }
        </div>

      </div>

      {/* ---------------------------if notifications are loading/fetching ---------- */}

      <div className="card bg-base-100 shadow-md ">
        {notificationDataLoading && (
          <div className="flex items-center justify-center py-10">
            <svg
              className={`animate-spin mr-1 h-12 w-12 text-white`}
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
            <div className="ml-10 text-center col-span-4 text-[#63b0e0]">
              Loading notifications...
            </div>
          </div>
        )}

        {/*-----------------------------notifications list--------------------- */}

        {notificationList?.map((data) => {
          const isViewNotificationDetails = viewNotificationDetailsType.includes(data?.notificationType);

          const reservationAssignmentId = data?.reservationsAssignmentsID;

          const toPath = isViewNotificationDetails
            ? `/assignments-management/reservation-assignment-details/${encryptParamID(reservationAssignmentId)}`
            : `/assignments-management`;
          return (
            <div
              key={data?.referenceID}
              className={`flex items-start w-full py-3 border-b last:border-b-0 border-[#0000005f] color-[${notificationTitle[data?.notificationType]}]}]`}
            >
              <div style={{ color: notificationTitle[data?.title] }}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5 text-7xl font-bold mx-3 mt-[3.2px]"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
                  />
                </svg>
              </div>
              <div className="flex items-start flex-col w-11/12 ">
                <p className="text-[15px] text-[#63b0e0]">
                  {data?.title ?? "N/A"}
                </p>
                <p className="text-[15px]">{data?.body || "N/A"}</p>
                <p className="text-[13px] text-[#63b0e0]">
                  Assignment ID:{" "}
                  <span
                    className="cursor-pointer tooltip text-[#7ac46b]"
                    data-tip="View Assignment"
                  >
                    <Link
                      className="cursor-pointer"
                      to={toPath}
                      onClick={() =>
                        dispatch(
                          updateAssignmentFilters({ reservationsAssignmentsID: reservationAssignmentId })
                        )
                      }
                    >
                      {data?.assgnNum}
                    </Link>
                  </span>
                </p>
                {/* ) */}
              </div>
              <div className="flex items-start w-1/12 justify-end  mx-3">
                <p className="text-[15px] text-[#63b0e0]">
                  {formatDateTimeFor_Today_Yesterdy_DaysAgo(data?.createdDate)}
                </p>
              </div>
            </div>
          );
        })}

        {!notificationDataLoading && notificationList?.length === 0 && (
          <div className="my-10 text-center col-span-4 text-[#63b0e0]">
            You have no notifications right now!
          </div>
        )}
      </div>

      {/*-------------------------------------- popup for notification clear ----------------------------------------------*/}

      <input
        type="checkbox"
        checked={notificationOpenModal}
        id="notificationpopup"
        className="modal-toggle"
        onChange={() => setNotificationOpenModal(!notificationOpenModal)} // Toggle the modal
      />
      {notificationOpenModal && (
        <div className="modal">
          <div className="modal-box relative">
            <button
              className="btn btn-sm btn-circle absolute right-2 top-2"
              onClick={() => setNotificationOpenModal(false)} //clear the modal
            >
              ✕
            </button>
            <h3 className="text-lg font-bold text-center">
              Are you sure you want to clear all notifications?
            </h3>
            <div className="modal-action justify-center">
              <button
                className="btn btn-md bg-[red] hover:bg-[red] text-[#fff] text-sm hover:text-[#fff] ml-4"
                onClick={
                  () => deleteNotification() // Close the modal after action
                }
              >
                Yes
              </button>
              <button
                className="btn btn-md bg-[#000] text-[#fff] text-sm hover:bg-[#000]"
                onClick={() => setNotificationOpenModal(false)} // Close the modal
              >
                No
              </button>
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

export default NotificationList;
