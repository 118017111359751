import React, { useEffect, useState } from "react";
import { MdOutlineRefresh, MdOutlineSearch } from "react-icons/md";
import { useSelector, useDispatch } from "react-redux";
import { setClaimantFilters } from "../../../../Redux-Toolkit/ClaimantsSlice";
import { Input } from "react-daisyui";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const ClaimantFilter = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const defaultState = {
    claimantID: 0,
    firstName: "",
    lastName: "",
    email: "",
    mobile: "",
    inactive: -2,
    languageCode: "",
    miles: 0,
    zipCode: 0,
    page: 1,
  };

  const initializeState = (filter = {}) => ({
    ...defaultState,
    ...filter,
  });

  const claimantsFilter = useSelector((state) => state?.claimants?.claimantFilters);
  const ClaimantLanguage = useSelector((state) => state?.claimants?.languageList) || [];

  const initialState = initializeState(claimantsFilter);
  const [claimantID, setClaimantID] = useState(initialState.claimantID);
  const [firstName, setFirstName] = useState(initialState.firstName);
  const [lastName, setLastName] = useState(initialState.lastName);
  const [email, setEmail] = useState(initialState.email);
  const [mobile, setMobile] = useState(initialState.mobile);
  const [inactive, setInactive] = useState(initialState.inactive);
  const [languageCode, setLanguageCode] = useState(initialState.languageCode);
  const [miles, setMiles] = useState(initialState.miles);
  const [zipCode, setZipCode] = useState(initialState.zipCode);
  const [page, setPage] = useState(initialState.page);

  useEffect(() => {
    updateStateFromFilter(claimantsFilter);
  }, [claimantsFilter]);

  const updateStateFromFilter = (filter) => {
    setClaimantID(filter?.claimantID);
    setFirstName(filter?.firstName);
    setLastName(filter?.lastName);
    setEmail(filter?.email);
    setMobile(filter?.mobile);
    setInactive(filter?.inactive);
    setLanguageCode(filter?.languageCode);
    setMiles(filter?.miles);
    setZipCode(filter?.zipCode);
    setPage(filter?.page);
  };

  const handleReset = () => {
    resetState();
    dispatch(setClaimantFilters(createFilterData()));
    navigate(`/claimant-management`);
  };

  const resetState = () => {
    setClaimantID(0);
    setFirstName("");
    setLastName("");
    setEmail("");
    setMobile("");
    setLanguageCode("");
    setMiles(0);
    setZipCode(0);
    setInactive(-2);
  };

  const createFilterData = () => ({
    claimantID: 0,
    firstName: "",
    lastName: "",
    email: "",
    mobile: "",
    inactive: -2,
    languageCode: "",
    miles: 0,
    zipCode: 0,
    page: page,
    limit: 20,
  });

  const validateZipMiles = () => {
    if ((miles && !zipCode) || (zipCode && !miles)) {
      toast.error("Both miles and zipcode are required fields.", {
        position: toast.POSITION.TOP_CENTER,
      });
      return false;
    }
    return true;
  };

  return (

    <div className="card bg-base-100 shadow-md p-6 mt-3 mb-3">
      <form
        onSubmit={(e) => {
          e.preventDefault();
          if (validateZipMiles()) {
            const data = {
              ...(claimantID && claimantID.trim() !== "" && { claimantID }),
              ...(firstName && firstName.trim() !== "" && { firstName }),
              ...(lastName && lastName.trim() !== "" && { lastName }),
              ...(email && email.trim() !== "" && { email }),
              ...(mobile && mobile.trim() !== "" && { mobile }),
              ...(inactive &&
                !isNaN(Number(inactive)) && {
                  inactive: Number(inactive),
                }),
              ...(languageCode && languageCode.trim() !== "" && { languageCode }),
              ...(zipCode && zipCode.trim() !== "" && { zipCode }),
              ...(miles && miles.trim() !== "" && { miles }),
              page: 1,
              limit: 20,
            };
            dispatch(setClaimantFilters(data)); // to update the filter states in store to persist filter values
            navigate(`?page=1`);
          }
        }}
      >
        <div className="grid lg:grid-cols-3 md:grid-cols-2 md:gap-8 gap-4">
          <div>
            <label
              htmlFor="claimantid"
              className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
            >
              Claimant ID
            </label>
            <input
              name="claimantid"
              onChange={(e) => {
                const value = e.target.value;
                if (/^\d*$/.test(value)  && value.length <= 9) {
                  setClaimantID(e.target.value)
                }
              }}
              value={claimantID === 0 ? "" : claimantID}
              placeholder="Claimant ID"
              id="claimantid"
              type="text"
              autoComplete="off"
              className="input focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 input-bordered"
            />
          </div>

          <div>
            <label
              htmlFor="firstname"
              className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
            >
              First Name
            </label>
            <input
              name="firstname"
              onChange={(e) => { setFirstName(e.target.value) }}
              value={firstName}
              placeholder="First Name"
              id="firstname"
              type="text"
              autoComplete="off"
              className="input focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 input-bordered"
            />
          </div>
          <div>
            <label
              htmlFor="lastname"
              className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
            >
              Last Name
            </label>
            <input
              name="lastname"
              onChange={(e) => setLastName(e.target.value)}
              value={lastName}
              placeholder="Last Name"
              id="lastname"
              type="text"
              autoComplete="off"
              className="input focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 input-bordered"
            />
          </div>
          <div>
            <label
              htmlFor="email"
              className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
            >
              Email
            </label>
            <input
              name="email"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              placeholder="Email"
              id="email"
              type="text"
              autoComplete="off"
              className="input focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 input-bordered"
            />
          </div>
          <div>
            <label
              htmlFor="mobile"
              className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
            >
              Mobile
            </label>
            <input
              name="mobile"
              onChange={(e) => {
                const value = e.target.value;
                if (value.length <= 10 && /^\d*$/.test(value)) {
                  setMobile(value);
                }
              }}
              value={mobile}
              placeholder="Mobile"
              id="mobile"
              type="phone"
              autoComplete="off"
              className="input focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 input-bordered"
            />
          </div>

          <div>
            <label
              htmlFor="claimantStatus"
              className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
            >
              Claimant Status
            </label>
            <select
              id="claimantStatus"
              onChange={(e) => setInactive(e.target.value)}
              name="claimantStatus"
              value={inactive}
              className={`select focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 select-bordered`}
            >
              <option value={-2}>All</option>
              <option value={0}>Active</option>
              <option value={-1}>Inactive </option>
            </select>
          </div>

          <div>
            <label
              htmlFor="language"
              className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
            >
              Language
            </label>
            <select
              id="language"
              value={languageCode}
              onChange={(e) => setLanguageCode(e.target.value)}
              name="language"
              className={`select input focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 select-bordered`}
            >
              <option value="" className="">
                All
              </option>
              {ClaimantLanguage &&
                ClaimantLanguage?.length > 0 &&
                ClaimantLanguage?.map((language, index) => (
                  <option value={language.code} key={language.code}>
                    {language.description}
                  </option>
                ))}

            </select>
          </div>

          <div>
            <label
              htmlFor="zipcode"
              className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
            >
              Zip Code
            </label>
            <Input
              name="zipCode"
              placeholder="Zip Code"
              onChange={(e) => {
                const value = e.target.value;
                if (value.length <= 10 && /^\d*$/.test(value)) {
                  setZipCode(value);
                }
              }}
              value={zipCode === 0 ? "" : zipCode}
              id="zipcode"
              type="zipcode"
              autoComplete="off"
              className="focus:outline-0 w-full text-sm font-medium rounded-md"
            />
          </div>

          <div>
            <label
              htmlFor="mile"
              className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
            >
              Miles
            </label>
            <Input
              name="mile"
              placeholder="Miles"
              onChange={(e) => {
                const value = e.target.value;
                if (value.length <= 10 && /^\d*$/.test(value)) {
                  setMiles(value);
                }
              }}
              value={miles === 0 ? "" : miles}
              id="mile"
              type="mile"
              autoComplete="off"
              className="focus:outline-0 w-full text-sm font-medium rounded-md"
            />
          </div>

        </div>
        <div className="lg:mt-3 mt-4 flex items-center md:justify-end justify-between">
          <button
            type="reset"
            onClick={() => {
              handleReset();
            }}
            className="btn btn-md bg-[#000] text-green text-sm hover:bg-[#000]"
          >
            <MdOutlineRefresh className="mr-1" />
            Reset
          </button>
          <button className="btn btn-md bg-green text-[#000] text-sm hover:text-green hover:bg-[#000] ml-4">
            <MdOutlineSearch className="mr-1" />
            Search
          </button>
        </div>
      </form>
    </div>
  );
};

export default ClaimantFilter;
