import { differenceInMilliseconds, parseISO } from "date-fns";
import moment from "moment";
import haversine from "haversine-distance";

/**
 * @param {string} dateTime string
 * @returns {string} formatted time in format 5:32:29 PM
 */
export function getTimeFromString(dateString) {
  if (!dateString) return "N/A";

  return parseISO(`${dateString}`).toLocaleTimeString("en-US", {
    timeZone: "Asia/Kolkata",
    hour12: true,
  });
}

/**
 * @param {string} dateTime string
 * @returns {string} formatted time difference between two datetime strings in format 00 : 05 : 10
 */
export function getTimeDifference(startTime, endTime) {
  if (!startTime || !endTime) return "N/A";

  const startDateandTime = startTime;
  const endDateandTime = endTime;

  const startDate = parseISO(startDateandTime);
  const endDate = parseISO(endDateandTime);

  const diffInMs = differenceInMilliseconds(endDate, startDate);

  const totalSeconds = Math.floor(diffInMs / 1000);
  const totalMinutes = Math.floor(totalSeconds / 60);
  const totalHours = Math.floor(totalMinutes / 60);

  const seconds = String(totalSeconds % 60).padStart(2, "0");
  const minutes = String(totalMinutes % 60).padStart(2, "0");
  const hours = String(totalHours).padStart(2, "0");

  return `${hours} : ${minutes} : ${seconds}`;
}

export function formatDate(dateString) {
  if (!dateString) return "N/A";

  const formatStartDate = moment(dateString).format("MM-DD-yyyy");  

  return formatStartDate;
}

export function parseJson(object) {
  if (!object) return {};

  if (typeof object === "object") {
    return object;
  }

  try {
    return JSON.parse(object);
  } catch (e) {
    return null;
  }
}

export const formatDateTimeFor_Today_Yesterdy_DaysAgo = (sentDate) => {
  const date = new Date(sentDate);
  const now = new Date();
  const yesterday = new Date(now);
  yesterday.setDate(yesterday.getDate() - 1);
  const tomorrow = new Date(now);
  tomorrow.setDate(tomorrow.getDate() + 1);

  // Format as hh:mm AM/PM
  const formatTime = (date) =>
    date.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });

  // Check if the date is today
  if (date.toDateString() === now.toDateString()) {
    return formatTime(date);
  }

  // Check if the date is yesterday
  if (date.toDateString() === yesterday.toDateString()) {
    return "Yesterday";
  }

  // Calculate the difference in days
  const diffTime = Math.abs(now - date);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

  return `${diffDays} days ago`;
};

export const extractDate = (dateTimeString) => {
  if (!dateTimeString) return "N/A";
  const dateObj = new Date(dateTimeString);
  if (isNaN(dateObj.getTime())) return "N/A"; // Check if the date is invalid

  const day = String(dateObj.getDate()).padStart(2, "0");
  const month = String(dateObj.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  const year = dateObj.getFullYear();
  return `${month}/${day}/${year}`;
};

// Days from now in future or past
export const formatDateDifference = (dateString) => {
  const date = new Date(dateString);
  const now = new Date();
  const yesterday = new Date(now);
  const tomorrow = new Date(now);
  yesterday.setDate(yesterday.getDate() - 1);
  tomorrow.setDate(tomorrow.getDate() + 1);

  // Check if the date is today
  if (date.toDateString() === now.toDateString()) {
    return "Today";
  }

  // Check if the date is yesterday
  if (date.toDateString() === yesterday.toDateString()) {
    return "Expired";
  }

  // Check if the date is tomorrow
  if (date.toDateString() === tomorrow.toDateString()) {
    return "Tomorrow";
  }

  // Calculate the difference in days
  const diffTime = date - now;
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

  // Check if the date is older than today
  if (diffDays < 0) {
    return "Expired";
  }

  if (diffDays > 0) {
    return `In ${diffDays} days`;
  } 
  
  else {
    return `N/A`;
  }
};

/**
 * calculate distance between two points using haversine formula for google map
 * @param {*} path
 * @returns
 */
export function calculateTotalDistance(path) {
  let totalDistance = 0.0;
  for (let i = 0; i < path.length - 1; i++) {
    const pointA = path[i];
    const pointB = path[i + 1];
    totalDistance += haversine(pointA, pointB);
  }
  const totalDistanceInKm = (totalDistance / 1000).toFixed(2);
  return parseFloat(totalDistanceInKm);
}

/**
 * Helper function to parse JSON safely and handle errors.
 * @param {string} jsonString - The JSON string to parse.
 * @returns {Object|null} - The parsed JSON object, or null if parsing failed.
 */
export const safeParseJSON = (jsonString) => {

  if (typeof(jsonString) === "object" && jsonString !== null) {
    return jsonString;
  }
  try {
    return JSON.parse(jsonString);
  } catch (error) {
    console.error("Failed to parse JSON:", error);
    return null;
  }
};

/**
 * Helper fucntion to serialize coordinates object to JSON object from string
 * @param {*} jsonString
 * @returns parsed coordinates object
 */

export function deserializeCoordinates(jsonString) {
  if (typeof(jsonString) === "object" && jsonString !== null) {
    return jsonString;
  }
  const obj = JSON.parse(jsonString);
  return {  
    latitude: parseFloat(obj.latitude),
    longitude: parseFloat(obj.longitude),
  };
}

/**
 * Helper function to log console methods based on the environment
 * @returns console methods based on the environment
 */
export const devConsole = () => {
  const isDevelopment = process.env.NODE_ENV === "development";
  const devConsole = {};

  ["log", "warn", "error", "info", "debug", "trace"].forEach((method) => {
    devConsole[method] = (message, ...optionalParams) => {
      if (isDevelopment) {
        console[method](message, ...optionalParams);
      }
    };
  });

  return devConsole;
};

/**
 * Helper function to log active/inactive methods based on the environment in all components
 * @param {*} data 
 * @returns active/inactive methods based on the environment
 */

export const inactiveFlagClass = (data) => {
  if (data?.inactiveflag === -1) {
    return "bg-[red] hover:bg-[red]"
  }
  else if (data?.inactiveflag === 0) {
    return "bg-[#39ff14] hover:bg-[#39ff14]"
  }
  else {
    return "bg-warning hover:bg-warning"
  }
}


export const iDValue = (value) => value === 0 ? "" : value;

export const handleIDInputChange = (e, setterFunction) => {
  const value = e.target.value;
  // Allow only numeric input and limit it to 10 digits
  if (/^\d*$/.test(value) && value.length <= 9) {
    setterFunction(Number(value));
  }
};
